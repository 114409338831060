export const projectsHeaderText = {
  title: 'PROJECTS',
  contentList: [
    'androidstudio',
    'apache',
    'aws',
    'cypress',
    'framermotion',
    'graphql',
    'java',
    'kotlin',
    'nextjs',
    'nodejs',
    'opencv',
    'playwright',
    'python',
    'pytorch',
    'react',
    'ruby',
    'storybook',
    'tensorflow',
    'typescript',
    'androidstudio',
    'apache',
    'aws',
    'cypress',
    'framermotion',
    'graphql',
    'java',
    'kotlin',
    'nextjs',
    'nodejs',
    'opencv',
    'playwright',
    'python',
    'pytorch',
    'react',
    'ruby',
    'storybook',
    'tensorflow',
    'typescript',
  ],
};
